import { Input, Button } from "antd";
import { useState} from "react";

import { useHistory } from "react-router";
import "./Login.css";
import loginImage from "../../images/login.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    toast.configure();
    const history = useHistory()
    const[email,setEmail]=useState("")
    const [password,setPassword]=useState("")
    async function login() {
        let item = { email, password }
        let result = await fetch(`${process.env.REACT_APP_BASEURL}/api/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(item)

        })
        result = await result.json()
        localStorage.setItem("user-info", JSON.stringify(result))
        
        if(result.success) {
            if (result.user.role === "admin" || result.user.role === "user") {
                toast.success(`Welcome ${result.user.name}!`, {
                    position:"top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                setTimeout(() => {
                    history.push("/dashboard")
                }, 1000)
            } 
        } else {
            toast.error('Invalid Login Credentials', {
                position:"top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }

       
    }

    return (
        <div className="login">
           
            <div className="loginImage">
                <img src={loginImage} alt="" />

            </div>
            <div className="logindetails">
                <h1 className="apollo">Apollo Offers Dashboard</h1>
                <div className="loginName">
                    <h1>Sign<span>In</span></h1>
                    <p>Enter your email and password to sign in</p>
                </div>

                <Input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <Button onClick={login} variant="contained" >Log in </Button>

            </div>

            <ToastContainer  />
        </div>

    )
}
export default Login;