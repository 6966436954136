import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadLogsData } from '../../redux/Actions'
import "./Logs.css"

const Logs = () => {
  const dispatch = useDispatch()
  const logData = useSelector(state => state.db.logs.data)
  console.log("logsData", logData)
  useEffect(() => {
    dispatch(loadLogsData())
  }, [])
  return (
    <div>
      <div className="dc-heading"><h4>Logs</h4></div>
      <ul className="logs-sec">
        {logData && logData ? logData?.map((log, i) => (<li>{log.log_msg}</li>)) : ""}
      </ul>

    </div>
  )
}

export default Logs