import React, { useState, useEffect, useRef } from 'react'
import './Dashboard.css'
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from '@mui/material/TableHead';
import { useDispatch, useSelector } from 'react-redux'

import { loadExcelData, loadUploadExcelData, loadUploadImageData,loadSingleOffer,offerUpdated,offerDeleted, loadAdminExcelData } from '../../redux/Actions';
import { Button,Modal } from 'antd'
import {toast,ToastContainer } from 'react-toastify';
import { Delete, Edit } from '@mui/icons-material';
import { DatePicker} from 'antd';
import moment from 'moment';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        // borderWidth: 0,
        // borderRightWidth: 1,
        // borderBottomWidth: 1,

        // borderColor: '#616161',
        // borderStyle: 'solid',

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        // borderWidth: 0,
        // borderRightWidth: 1,
        // borderBottomWidth: 1,
        // borderColor: '#616161',
        // borderStyle: 'solid',

    },
}));

const Dashboard = () => {
    const dispatch = useDispatch()
    const fileInputRef = useRef();
    const fileInputRefOne = useRef();


    const dbdetails = useSelector(state => state.db.data.data)
    console.log("db details", dbdetails)
    const singleOffer=useSelector(state=>state.db.offer.offer)
    console.log("single offer details",singleOffer)
    const er=useSelector(state=>state.db.error)
    console.log("error respone",er)
    const [selectedFile, setSelectedFile] = useState([])
    const [image, setImage] = useState([])

    const [isModalVisible, setIsModalVisible] = useState(false);
const[PromoStartDate,setStartDate]=useState("")
const[PromoEndDate,setEndDate]=useState("")

const user = JSON.parse(localStorage.getItem('user-info'))

    useEffect(() => {
        toast.configure()
        if(user.user.role==="admin"){
            console.log("role",user.user.role)
            dispatch(loadAdminExcelData())
            

        }
        else{
            dispatch(loadExcelData())
           
        }
    }, [])

const onChangeStartDate=(date, dateString)=>{
    setStartDate(dateString)

}
const onChangeEndDate=(date, dateString)=>{
    setEndDate(dateString)

}

    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };


        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired
    };


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dbdetails.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };








    const currentPosts = dbdetails && dbdetails ? dbdetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : ""

    function add3Dots(string, limit) {
        var dots = "...";
        var limit = 10
        if (string ? string.length > limit : "") {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }

    
    const changeFileHandler = (event) => {
        console.log("files in onChange", event.target.files)
        setSelectedFile(event.target.files[0])
        const formData = new FormData();
        formData.append('file', event.target.files[0], event.target.files[0].name)
        formData.append('email',user.user.email)

        console.log("fileUpload", formData)
        dispatch(loadUploadExcelData(formData))

        event.target.value = null
    }
    const changeImageHandler = (e) => {
        console.log("files in onChange", e.target.files)

        let formData = new FormData();

        for (let i = 0; i < e.target.files.length; i++) {
            // console.log("inside map something", image[i].name)
            formData.append('images', e.target.files[i], e.target.files[i].name)
        }
        formData.append('email',user.user.email)

        console.log("form data", formData)
        dispatch(loadUploadImageData(formData))


        e.target.value = null

    }
    const showModel = (id) => {
       dispatch(loadSingleOffer(id))
        setIsModalVisible(true)
    
    
      }
      const handleCancel= () => {
        setIsModalVisible(false);
        
      };
      useEffect(()=>{
          if(singleOffer){
              const singleStartDate=singleOffer[0].PromoStartDate
              const singleEndDate=singleOffer[0].PromoEndDate
              setStartDate(singleStartDate)
              setEndDate(singleEndDate)
          }

      },[singleOffer])

      const updateData={
        PromoStartDate,
        PromoEndDate
      }
      const updateHandler=(id)=>{
        console.log("id in update",id)
        dispatch(offerUpdated(updateData,id))
        setIsModalVisible(false)
        setStartDate("")
        setEndDate("")
      }
      const deleteHandler = (id) => {
        console.log("id is", id)
        dispatch(offerDeleted(id))
       
      }

      function disabledDate(current) {
        const start = moment(PromoStartDate, 'YYYY-MM-DD');
        return current < start;
    }

    return (
        <div>
            <div className="dc-heading"><h4>Dashboard</h4></div>
            <div className="upload">
                <div>
                    <Button onClick={() => fileInputRefOne.current.click()} >
                        UPLOAD IMAGE

                    </Button>
                    <input type='file' onChange={changeImageHandler} multiple ref={fileInputRefOne} hidden />



                </div>

                <div>
                    <Button onClick={() => fileInputRef.current.click()} >
                        UPLOAD FILE
                    </Button>
                    <input onChange={(event) => changeFileHandler(event)} multiple={false} ref={fileInputRef} type='file' hidden />

                </div>

            </div>
            <div className="table-container">
                <Paper sx={{
                    width: '100%', overflow: 'hidden', ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {

                        mt: 1.8,
                        mr: -1

                    },

                    ".MuiTablePagination-displayedRows": {
                        mt: 2
                    }
                }}>

                    <div className="promo">
                        <h6 className="promo-header">Promo Header</h6>
                        <h6 className="promo-items">Promo Items</h6>
                    </div>
                    <TableContainer component={Paper}>
                        <Table aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>

                                    <StyledTableCell className="table-head" align="left">promocode</StyledTableCell>
                                    <StyledTableCell className="table-head" align="left">promoTitle</StyledTableCell>
                                    <StyledTableCell className="table-head" align="left">promoBanner</StyledTableCell>
                                    <StyledTableCell className="table-head" align="left">BannerOrder</StyledTableCell>
                                    <StyledTableCell className="table-head" align="left">promoType</StyledTableCell>
                                    <StyledTableCell className="table-head" align="centre">promoAction</StyledTableCell>
                                    <StyledTableCell className="table-head" align="centre">StartDate</StyledTableCell>
                                    <StyledTableCell className="table-head" align="centre">EndDate</StyledTableCell>
                                    <StyledTableCell className="table-head" align="centre">ItemSelection</StyledTableCell>
                                    <StyledTableCell className="table-head" align="centre">SelectionType</StyledTableCell>
                                    <StyledTableCell className="table-head" align="centre" ><TableRow><TableCell  className="table-head">ArtImage</TableCell><TableCell  className="table-head">ArtCode</TableCell><TableCell  className="table-head">ProductName</TableCell><TableCell  className="table-head">Quantity</TableCell></TableRow></StyledTableCell>
                                   <StyledTableCell className="table-head"></StyledTableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentPosts && currentPosts ? currentPosts.map((db) => (
                                    <TableRow key={db.id}>
                                        <StyledTableCell className="promo-header-list" >{add3Dots(db.promocode)}</StyledTableCell>
                                        <StyledTableCell className="promo-header-list" >{add3Dots(db.promoTitle)}</StyledTableCell>
                                        <StyledTableCell className="promo-header-list" ><a href={"http://" + db.promoBanner}>{add3Dots(db.promoBanner)}</a></StyledTableCell>
                                        <StyledTableCell className="promo-header-list" >{add3Dots(db.promoBannerOrder)}</StyledTableCell>
                                        <StyledTableCell className="promo-header-list" >{add3Dots(db.promoType)}</StyledTableCell>
                                        <StyledTableCell className="promo-header-list" >{add3Dots(db.promoAction)}</StyledTableCell>
                                        <StyledTableCell className="promo-header-list" >{db.PromoStartDate}</StyledTableCell>
                                        <StyledTableCell className="promo-header-list" >{db.PromoEndDate}</StyledTableCell>
                                        <StyledTableCell className="promo-header-list" >{add3Dots(db.promoItemSelection)}</StyledTableCell>
                                        <StyledTableCell className="promo-header-list" >{add3Dots(db.promoItemSelectionType)}</StyledTableCell>
                                        <StyledTableCell >
                                            {db.promoItems.map((promo) => (
                                                <TableRow>
                                                    <TableCell><a href={"http://" + promo.artImage}>{add3Dots(promo.artImage)}</a></TableCell>
                                                    <TableCell>{add3Dots(promo.artCode)}</TableCell>
                                                    <TableCell >{add3Dots(promo.productName)}</TableCell>
                                                    <TableCell>{add3Dots(promo.qty)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </StyledTableCell>

                                        <StyledTableCell>  {user.user.role==="admin"? <TableCell  align="centre"><div className="icon-dash"><p className="edit-dash"><Edit className="icons" onClick={() => showModel(db.id)} /></p><p ><Delete className="icons" onClick={() => deleteHandler(db.id)}   /></p></div></TableCell>:""}</StyledTableCell>


                                    </TableRow>



                                )) : ""}


                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>

                        </Table>

                    </TableContainer>
                    <TablePagination className="pagination"
                        rowsPerPageOptions={[5, 10, 25, { label: "All", value: dbdetails ? dbdetails.length : '' }]}
                        colSpan={3}
                        count={dbdetails ? dbdetails.length : null}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        // SelectProps={{
                        //     inputProps: {
                        //         "aria-label": "rows per page"
                        //     },
                        //     native: true
                        // }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>
            </div>
            {
        isModalVisible &&
        <div className='modal111'>
          <Modal
            visible={isModalVisible}
            onCancel={handleCancel}
            width={900}
            footer={[
              <Button key="submit" type="primary"  onClick={()=>updateHandler(singleOffer[0].id)}  className='vahan-edit-update'>
                Update
              </Button>,
            ]}
            className="logisiticsModal"
          >
            <div className='add-logistics'>
              <h4>Update Start & End date </h4>
            </div>
            <div className="calendar-section">
           < DatePicker className="date-picker" value={PromoStartDate !=="" ?moment(PromoStartDate):""} onChange={onChangeStartDate} placeholder="Start Date" />
           < DatePicker className="date-picker" value={PromoEndDate !=="" ?moment(PromoEndDate):""} onChange={onChangeEndDate} placeholder="End Date" disabledDate={disabledDate} />

              
            </div>
          </Modal>
        </div>
      }
          <ToastContainer />
        </div>
    )
}

export default Dashboard
