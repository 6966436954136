export const UPLOAD_EXCEL="UPLOAD_EXCEL"
export const GET_EXCEL="GET_EXCEL"
export const UPLOAD_IMAGE="UPLOAD_IMAGE"
export const ADD_USER="ADD_USER"
export const GET_USERS="GET_USERS"
export const GET_LOGS="GET_LOGS"
export const DELETE_USER="DELETE_USER"
export const GET_SINGLE_USER="GET_SINGLE_USER"
export const UPDATE_USER="UPDATE_USER"
export const GET_SINGLE_OFFER="GET_SINGLE_OFFER"
export const UPDATE_OFFER="UPDATE_OFFER"
export const DELETE_OFFER="DELETE_OFFER"










