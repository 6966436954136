import React from 'react'
import Avatar from '@mui/material/Avatar';
import { UserOutlined } from '@ant-design/icons';
import './Header.css'
import { Link, useHistory } from 'react-router-dom'
import logo from "../../.././src/images/apollo-logo.png"
import { NavDropdown } from 'react-bootstrap'


const Header = (props) => {
    const history = useHistory()

    const logOut = () => {
        localStorage.clear()
        history.push("/")
    }
    let user = JSON.parse(localStorage.getItem('user-info'))


    return (
        <div>
            <div className="head">
                <img src={logo} width="50px" height="50px" alt="brand logo" />

                <div className="avatar" >
                    <Avatar icon={<UserOutlined />} />
                    <div>
                        <NavDropdown title={user && user.user.name} id="user-info">
                            <NavDropdown.Item onClick={logOut}>Log out</NavDropdown.Item>
                        </NavDropdown>

                    </div>
                </div>

            </div>
            <nav className="nav">

                <li>
                    {user.user.role === "admin" ?
                        <>
                            <Link to="/dashboard"> <ul>Dashboard</ul></Link>
                            <Link to="/users">  <ul>Users</ul></Link>
                            <Link to="/logs">  <ul>Logs</ul></Link>

                        </> : ""}
                    {user.user.role === "user" ?
                        <>
                            <Link to="/dashboard"> <ul>Dashboard</ul></Link>


                        </> : ""}





                </li>
            </nav>

        </div>
    )
}

export default Header
