import './App.css'
import { Fragment} from 'react';
import {Switch,Route} from 'react-router-dom'
import Login from './components/Login/Login';
import Header  from './components/Header/Header';
import Dashboard from './components/Dashboard/Dashboard';
import Users from './components/Users/Users';
import Logs from './components/Logs/Logs';
function App() {
  
return(
  <div className="App">
  <Switch>
   <Route path="/" exact component={Login} />
   <Route path='/dashboard' render={props =>
          <Fragment>
            < Header />
            <Dashboard />
          </Fragment>
        } />
        <Route path='/users' render={props =>
          <Fragment>
            < Header />
            <Users />
          </Fragment>
        } />
        <Route path='/logs' render={props =>
          <Fragment>
            < Header />
            <Logs />
          </Fragment>
        } />
  </Switch>

  </div>

)




}

export default App;
