
import * as types from './ActionTypes'
const initialState = {
    data: [],
    users: [],
    logs: [],
    user: {},
    addError:{},
    offer:{},
    updateError:{},
    loading: false
}

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_EXCEL:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case types.UPLOAD_EXCEL:
            return {
                ...state,
                loading: false
            }
        case types.UPLOAD_IMAGE:
            return {
                ...state,
                loading: false
            }
        case types.ADD_USER:
            console.log("action",action.payload)
            return {
                ...state,
                addError:action.payload,
                loading: false
            }
        case types.GET_USERS:
            return {
                ...state,
                users: action.payload,
                loading: false
            }
        case types.GET_LOGS:
            return {
                ...state,
                logs: action.payload,
                loading: false
            }
        case types.DELETE_USER:
            return {
                ...state,
                loading: false
            }
        case types.GET_SINGLE_USER:
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        case types.UPDATE_USER:
            return {
                ...state,
                updateError:action.payload,
                loading: false
            }
            case types.GET_SINGLE_OFFER:
                return {
                    ...state,
                    offer: action.payload,
                    loading: false
                }
                case types.UPDATE_OFFER:
            return {
                ...state,
                loading: false
            }
            case types.DELETE_OFFER:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}
export default DashboardReducer