import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'antd'
import { inputLabelClasses } from "@mui/material/InputLabel";
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './Users.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addUserData, loadSingleUser, loadUsersData, userDeleted, userUpdated } from '../../redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { Delete, Edit } from '@mui/icons-material';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const Users = () => {
  const dispatch = useDispatch()
  const usersList = useSelector(state => state.db.users.users)
  console.log("userList", usersList)
  const singleUser = useSelector(state => state.db.user.user)
  console.log("single user", singleUser)
  const errorMsg=useSelector(state=>state.db.addError)

  console.log("error101",errorMsg)
  const updateErrorMsg=useSelector(state=>state.db.updateError)
  console.log("error update",updateErrorMsg)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleOne, setIsModalVisibleOne] = useState(false);
  const[buttonLoading,setButtonLoading]=useState(false)
  const[updateButtonLoading,setUpdateButtonLoading]=useState(false)



  const addUsersHandler = () => {
    setIsModalVisible(true)
    setName("")
    setEmail("")
    setPassword("")
  }
  const changeNameHandler = (e) => {
    setName(e.target.value.replace(/[^A-Za-z ]/gi, ""))
  }
  const changeEmailHandler = (e) => {
    setEmail(e.target.value)
  }
  const changePasswordHandler = (e) => {
    setPassword(e.target.value)
  }
  useEffect(() => {
    dispatch(loadUsersData())
  }, [])
  const userData = {
    name,
    email,
    password
  }


  const submitHandlerUsers = (e) => {
    toast.configure()
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    e.preventDefault()
    if (!name || !email || !password) {
      toast.warning("Please Enter All Input Fields", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
      });
      setIsModalVisible(true)
    }
    else if (!email || regex.test(email) === false) {
      toast.warning('Please Add a Valid Email Address', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
      });
      setIsModalVisible(true)
    } else {
      dispatch(addUserData(userData))
      setButtonLoading(true)
     

    }


  }
useEffect(()=>{
  if(buttonLoading && errorMsg.success){
    setIsModalVisible(false)
    setName("")
    setEmail("")
    setPassword("")
    
  }
  
},[buttonLoading,errorMsg])
  const handleCancel = () => {
    setIsModalVisible(false);
    setName("")
    setEmail("")
    setPassword("")
  };

  const user = JSON.parse(localStorage.getItem('user-info'))
  const deleteHandler = (id) => {
    console.log("id is", id)
    dispatch(userDeleted(id))
   
  }

  useEffect(()=>{
    if(singleUser){
      const singleUserName=singleUser[0].name
      console.log("singleUserName",singleUserName)
      // const singleUserPassword=singleUser[0].password
      // console.log("singleUserPassword",singleUserPassword)

      setName(singleUserName)
      // setPassword(singleUserPassword)
    }

  },[singleUser])
  const showModel = (id) => {
    dispatch(loadSingleUser(id))
    setIsModalVisibleOne(true)


  }
  const handleCancelOne = () => {
    setIsModalVisibleOne(false);
    setName("")
    setPassword("")
  };
  const updateData={
    name,
    password
  }
  const updateHandler=(id)=>{
    console.log("id in update",id)
    dispatch(userUpdated(updateData,id))
    setUpdateButtonLoading(true)
    // setIsModalVisibleOne(false)
    // setName("")
    // setEmail("")
  }
  useEffect(()=>{
    if(updateButtonLoading && updateErrorMsg.success){
    setIsModalVisibleOne(false)
    setName("")
    setEmail("")
    setPassword("")
      
    }

  },[updateButtonLoading,updateErrorMsg])

  return (
    <div>
      <div className="dc-heading"><h4>Users</h4></div>
      <div className="adduser">
        {user.user.role === "admin" ? <Button onClick={addUsersHandler}>ADD USER</Button> : ""}
      </div>
      <div className="table" >
        <TableContainer component={Paper} >
          <Table sx={{ minWidth: 550 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-head">No</StyledTableCell>
                <StyledTableCell className="table-head" align="centre">Name</StyledTableCell>
                <StyledTableCell className="table-head" align="centre">Email</StyledTableCell>
                <StyledTableCell className="table-head" align="centre">Action</StyledTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {usersList && usersList ? usersList.map((user, index) => (
                <TableRow key={index}>
                  <StyledTableCell className="table-body" component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell className="table-body" align="centre">{user.name}</StyledTableCell>
                  <StyledTableCell className="table-body" align="centre">{user.email}</StyledTableCell>
                 <StyledTableCell className="table-body" align="centre"><div className="icon-section"><p><Edit className="icons" onClick={() => showModel(user.id)} /></p><p ><Delete className="icons" onClick={() => deleteHandler(user.id)} /></p></div></StyledTableCell>

                </TableRow>
              )) : ""}
            </TableBody>
          </Table>
        </TableContainer>
      </div>


      {
        isModalVisible &&
        <div className='modal111'>
          <Modal
            visible={isModalVisible}
            onCancel={handleCancel}
            width={900}
            footer={[
              <Button key="submit" type="primary" onClick={(e) => submitHandlerUsers(e)} className='vahan-edit-update'>
                Add User
              </Button>,
            ]}
            className="logisiticsModal"
          >
            <div className='add-logistics'>
              <h4>ADD USER </h4>
            </div>
            <div>
              <TextField id="standard-basic" label="Name" type="text" variant="standard" name="name" value={name} onChange={changeNameHandler} className="order-details-width text-field spacing-mt" InputLabelProps={{
                sx: {
                  color: "#FCB713",
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#FCB713"
                  }
                }
              }} />
              <TextField id="standard-basic" type="email" label="Email" variant="standard" name="email" value={email} onChange={changeEmailHandler} className="order-details-width text-field spacing-mt" InputLabelProps={{
                sx: {
                  color: "#FCB713",
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#FCB713"
                  }
                }
              }} />
              <TextField id="standard-basic" label="Password" variant="standard" name="password" value={password} onChange={changePasswordHandler} type="text" className="order-details-width text-field spacing-mt" InputLabelProps={{
                sx: {
                  color: "#FCB713",
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#FCB713"
                  }
                }
              }}

              />
            </div>
          </Modal>
        </div>
      }

      {
        isModalVisibleOne &&
        <div className='modal111'>
          <Modal
            visible={isModalVisibleOne}
            onCancel={handleCancelOne}
            width={900}
            footer={[
              <Button key="submit" type="primary" onClick={()=>updateHandler(singleUser[0].id)}  className='vahan-edit-update'>
                Update
              </Button>,
            ]}
            className="logisiticsModal"
          >
            <div className='add-logistics'>
              <h4>UPDATE USER </h4>
            </div>
            <div>
              <TextField id="standard-basic" label="Name" variant="standard" name="name" value={name} onChange={changeNameHandler} className="order-details-width text-field spacing-mt" InputLabelProps={{
                sx: {
                  color: "#FCB713",
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#FCB713"
                  }
                }
              }} />
              
              <TextField id="standard-basic" label="Set New Password" variant="standard" name="password" value={password} onChange={changePasswordHandler} type="text" className="order-details-width text-field spacing-mt" InputLabelProps={{
                sx: {
                  color: "#FCB713",
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#FCB713"
                  }
                }
              }}

              />
            </div>
          </Modal>
        </div>
      }
      <ToastContainer />
    </div>
  )
}

export default Users