import axios from 'axios'
import * as types from './ActionTypes'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const uploadExcel = () => ({
    type: types.UPLOAD_EXCEL
})
const getExcel = (data) => ({
    type: types.GET_EXCEL,
    payload: data

})
const uploadImages = () => ({
    type: types.UPLOAD_IMAGE
})
const addUsers=(addError)=>({
    type:types.ADD_USER,
    payload:addError

})

const getUsers = (users) => ({
    type: types.GET_USERS,
    payload: users

})
const getLogs = (logs) => ({
    type: types.GET_LOGS,
    payload: logs

})
const deleteUser=()=>({
    type:types.DELETE_USER
})
const deleteOffer=()=>({
    type:types.DELETE_OFFER
})
const getSingleUser = (user) => ({
    type: types.GET_SINGLE_USER,
    payload: user

})
const getSingleOffer = (offer) => ({
    type: types.GET_SINGLE_OFFER,
    payload: offer

})
const updateUser=(updateError)=>({
    type:types.UPDATE_USER,
    payload:updateError
})
const updateOffer=()=>({
    type:types.UPDATE_OFFER
})

export const loadExcelData = () => {
    const user = JSON.parse(localStorage.getItem('user-info'))

    return function (dispatch) {
      
        axios.get(`${process.env.REACT_APP_BASEURL}/api/all-offers?email=${user.user.email}`, { headers: { "Authorization": `Bearer ${user.user.token}` } })
        
            .then((response) => {
                
                console.log(response.data)
                dispatch(getExcel(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadAdminExcelData = () => {
    const user = JSON.parse(localStorage.getItem('user-info'))

    return function (dispatch) {
      
        axios.get(`${process.env.REACT_APP_BASEURL}/api/all-offers`, { headers: { "Authorization": `Bearer ${user.user.token}` } })
        
            .then((response) => {
                
                console.log(response.data)
                dispatch(getExcel(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadUploadExcelData = (upload) => {
    toast.configure();

    const user = JSON.parse(localStorage.getItem('user-info'))

    return function (dispatch) {

        axios.post(`${process.env.REACT_APP_BASEURL}/api/offer-upload`, upload, { headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
                if (response) {
                    toast.success('Uploaded File Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                dispatch(uploadExcel())
                dispatch(loadExcelData())
            })
            .catch((error) => {
                if (error.response) {
                    console.log("inside catch if block", error.response)

                    toast.error(`${error.response.data.message}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });


                }

            })

    }
}
export const loadUploadImageData = (upload) => {
    toast.configure();

    const user = JSON.parse(localStorage.getItem('user-info'))

    return function (dispatch) {

        axios.post(`${process.env.REACT_APP_BASEURL}/api/upload-images`, upload, { headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
                if (response) {
                    toast.success('Uploaded Images Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                dispatch(uploadImages())
            })
            .catch((error) => {
                if (error.response) {
                    console.log("inside catch if block", error.response)

                    toast.error(`${error.response.data.message}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });


                }

            })

    }
}
export const loadUsersData = () => {
    const user = JSON.parse(localStorage.getItem('user-info'))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/user/all-users`, { headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
                console.log(response.data)
                dispatch(getUsers(response.data))
            })
            .catch((error) => console.log(error))
    }
}


export const addUserData = (userData) => {
    toast.configure();

    console.log("userData",userData)
    const user = JSON.parse(localStorage.getItem('user-info'))

    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/user/add`, userData,{ headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
                if (response) {
                    toast.success('Added User Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                dispatch(addUsers(response.data))
                console.log("success response 001",response.data)
                dispatch(loadUsersData())
            })
            .catch((error) =>{
                if (error.response) {
                   dispatch(addUsers(error.response.data))
                   console.log("error response 001",error.response.data)
                    toast.error(`${error.response.data.message}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });


                }

            })
    }
}

export const loadLogsData = () => {
    const user = JSON.parse(localStorage.getItem('user-info'))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/all-logs`, { headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
                console.log(response.data)
                dispatch(getLogs(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const userDeleted = (id) => {
    const user = JSON.parse(localStorage.getItem('user-info'))
    toast.configure()

    return function (dispatch) {
        axios.delete(`${process.env.REACT_APP_BASEURL}/api/user/delete/${id}`, { headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
                if (response) {
                    toast.success('Deleted User Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                console.log(response.data)
                dispatch(deleteUser())
                dispatch(loadUsersData())
            })
            .catch((error) => console.log(error))
    }
}

export const loadSingleUser = (id) => {
    const user = JSON.parse(localStorage.getItem('user-info'))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/user/${id}`, { headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
               
                console.log(response.data)
                dispatch(getSingleUser(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const userUpdated = (data,id) => {
    toast.configure()

    const user = JSON.parse(localStorage.getItem('user-info'))
    console.log("id in action update",id,data)


    return function (dispatch) {
        axios.put(`${process.env.REACT_APP_BASEURL}/api/user/update/${id}`,data, { headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
                if (response) {
                    toast.success('Updated User Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
               
                console.log(response.data)
                dispatch(updateUser(response.data))
                dispatch(loadUsersData())
            })
            .catch((error) => {
                if(error.response){
                    dispatch(updateUser(error.response.data))
                    toast.error(`${error.response.data.message}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
            })
    }
}


export const loadSingleOffer = (id) => {
    const user = JSON.parse(localStorage.getItem('user-info'))
console.log("offer id ",id)
    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/offer/${id}`, { headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
               
                console.log(response.data)
                dispatch(getSingleOffer(response.data))
            })
            .catch((error) => console.log(error))
    }
}


export const offerUpdated = (data,id) => {
    toast.configure()

    const user = JSON.parse(localStorage.getItem('user-info'))


    return function (dispatch) {
        axios.put(`${process.env.REACT_APP_BASEURL}/api/offer/${id}`,data, { headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
                if (response) {
                    toast.success('Updated Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
               
                console.log(response.data)
                dispatch(updateOffer())
                dispatch(loadExcelData())
            })
            .catch((error) =>{
                if(error.response){
                    toast.error(`${error.response.data.message}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
            })
    }
}


export const offerDeleted = (id) => {
    const user = JSON.parse(localStorage.getItem('user-info'))
    toast.configure()

    return function (dispatch) {
        axios.delete(`${process.env.REACT_APP_BASEURL}/api/offer/${id}`, { headers: { "Authorization": `Bearer ${user.user.token}` } })
            .then((response) => {
                if (response) {
                    toast.success('Deleted Offer Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                console.log(response.data)
                dispatch(deleteOffer())
                dispatch(loadExcelData())
            })
            .catch((error) => console.log(error))
    }
}


<ToastContainer />
